div.ant-layout-sider-trigger{
    height: 5vh;
}

.scroll-sidebar {
    overflow-y: scroll;
    position: fixed;
    height: 95vh;
}

.scroll-body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0);
}

.scroll-body::-webkit-scrollbar {
    width: 4px;
    background-color: rgba(255, 255, 255, 0);
}

.scroll-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: rgba(51, 122, 183, 0.3);
}