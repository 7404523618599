.setImage {
    background-image: url('../images/login.jpg');
}

.setColor {
    background-color:transparent;
}

html, body
{
    height: 100vh;
}
#login-component .card#signup-form {
    margin-top: 10vh;
}
#login-component .card#login-form {
    width: 25rem;
    background: rgba(0, 0, 0, .72) !important;
    margin-top: 10%;
}

#login-component .card#login-form .card-title {
    color: white;
    font-size: 2em;
    margin-top: 1%;
    margin-bottom: 1em;
}


#login-component .alert {
    position: fixed;
    top: 0%;
    width: 60em;
}