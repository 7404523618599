/* Malls */
#malls-component .card{
    transition: transform .2s;
}
#malls-component .card:hover {
    cursor: pointer;
    transform: scale(1.1);
    z-index: 2;
}

#malls-component #footer-options{
    margin-top: 0.5rem;
    text-align: end;
}

#malls-component #footer-options button{
    margin-left: 0.5em;
}

/* Local malls */
#local-malls-component .card{
    transition: transform .2s;
}
#local-malls-component .card:hover {
    cursor: pointer;
    transform: scale(1.1);
    z-index: 2;
}

#local-malls-component #footer-options{
    margin-top: 0.5rem;
    text-align: end;
}

#local-malls-component #footer-options button{
    margin-left: 0.5em;
}